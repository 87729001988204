import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from './_services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'orderpos-webview';
  locationNotAllowed = false;
  
  constructor(private dataService:DataService, private activatedRoute:ActivatedRoute){
    
  }
}
