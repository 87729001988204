import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  private commonUserDataInfo:any ;
  private userInfo:any;
  private roles:any = [];
  private storeList:any = [];
  private auth:any;

  constructor() { }

    setUserFromAuthResponse(authResponse:any) {
    this.commonUserDataInfo = authResponse.userInfo;
    this.userInfo = authResponse.userInfo;
    this.userInfo.storeAccess = environment.STORE_CODE;
    this.roles = authResponse.roles;
    this.storeList = authResponse.storeListModals;
    this.auth = authResponse.commonMasterUserInfo?.accessToken;
  }

  setUserInfo(orderPOSUserInfo:any){
    this.userInfo = orderPOSUserInfo;
  }

  getCommonDataUserInfo(){
    return this.commonUserDataInfo;
  }

  getUserInfo() {
    return this.userInfo;
  }
  
  getAuthToken() {
    return this.auth;
  }

  getRoles(){
    return this.roles
  }
  
  getStores(){
    return this.storeList
  }
}
