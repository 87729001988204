import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserSessionService } from './user-session.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  occasionList: any;
  guestTitles: any;

  constructor(
    private http: HttpClient,
    private user: UserSessionService,
    private datepipe: DatePipe) {
  }

  clearCache() {
    sessionStorage.removeItem("_mm.o");
    localStorage.removeItem("_ocd");
    localStorage.removeItem("_ocd.n");
    localStorage.removeItem("_gtd");
    localStorage.removeItem("_tnd");
    localStorage.removeItem("_tbl");
    localStorage.removeItem("_stmd");
  }

  fetchBill(bkId: string) {
    return this.http.get(environment.ORDERPOS_BASEURL + "/api/Bill/detail/" + bkId);
  }

  fetchBNPaymentList(bkId: string, totalAmount: number) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Bill/payments", {
      "crsBookingId": bkId,
      "orderPOSBillAmount": totalAmount
    });
  }


  fetchCRMProfile(mobNo: string) {
    return this.http.get(environment.ORDERPOS_BASEURL + "/api/Customer/profile/" + mobNo);
  }

  validatePayment(bkId: string, totalAmount: number, cardNo?: any, cashAmt?: any, slipImgName?: string) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Bill/validate", {
      "crsBookingId": bkId,
      "validateCard": cardNo ? cardNo : undefined,
      "fileNames": slipImgName ? [slipImgName] : [],
      "orderPOSBillAmount": totalAmount,
      "cashAmount": cashAmt ? cashAmt : undefined,
      "storeCode": this.user.getUserInfo().storeAccess
    });
  }

  uploadAttachment(formData: FormData) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Bill/attachments/temp", formData);
  }

  submitProofs(orderDetailId:any, attachments:any){
    return this.http.post(environment.ORDERPOS_BASEURL +  "/api/Bill/updateitemproof" , {
      "orderDetailId": orderDetailId,
      "attachment": attachments
    });
  }

  fetchProofs(orderDetailId:any){
    return this.http.get(environment.ORDERPOS_BASEURL +  "/api/Bill/itemproof/" + orderDetailId).pipe(map((response: any) => {
      let arr = []; 
      for(let m of response.data.attachment){
        arr.push(environment.ORDERPOS_BASEURL + "/api/Bill/attachments/" + m);
      }
      response.data.attachment = arr;
      return response;
    }));
  }

  fetchCRSOpenTables() {
    const dt = new Date();
    let latest_date = this.datepipe.transform(dt, 'dd-MMM-yyyy') || "";
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Bill/crsopentables", {
      "storeCode": this.user.getUserInfo().storeAccess,
      "reservationDate": latest_date
    });
  }

  fetchCounters(){
    return this.http.get(environment.ORDERPOS_BASEURL + "/api/Master/counter/" + this.user.getUserInfo().storeAccess);
  }

  fetchOrdersByCounter(counterId:any){
    return this.http.get(environment.ORDERPOS_BASEURL + "/rest/order/listByCounter/" + this.user.getUserInfo().storeAccess + "/" + counterId + "?live=1");
  }

  submitItemStatus(itemOrderIds:any, statusId:any){
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/order/status/update", {
      "orderIds": itemOrderIds,
      "statusId": Number(statusId)
    });
  }

  fetchVoucherList(bkId: string) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Voucher/list", {
      "crsBookingId": bkId,
    });
  }


  validateAndAddVoucher(
    vCode: string, orderDetails: any, orderedItems: any, pin?: any) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Voucher/add", {
      "barcode": vCode,
      "crsBookingId": orderDetails.bookingId,
      "pin": pin,
      "name": orderDetails.customer.customerName,
      "orderItemDetails": orderedItems,
      "reservationDate": orderDetails.bkDate,
      "cover": orderDetails.pax,
      "amount": orderDetails.totalGrossAmount,
      "mobileNumber": orderDetails.customer.customerPhone,
      "storeCode": orderDetails.storeCode,
      "receiptNo": orderDetails.receiptNo
    });
  }

  removeVoucher(orderDetails: any, orderedItems: any) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Voucher/remove", {
      "crsBookingId": orderDetails.bookingId,
      "name": orderDetails.customer.customerName,
      "orderItemDetails": orderedItems,
      "reservationDate": orderDetails.bkDate,
      "cover": orderDetails.pax,
      "amount": orderDetails.totalAmount,
      "mobileNumber": orderDetails.customer.customerPhone,
      "storeCode": orderDetails.storeCode,
      "receiptNo": orderDetails.receiptNo
    });
  }

  //ORDERPOS
  fetchReservationList(dt: any) {
    let noStatusChangeFor = ["SEATED", "SETTLED", "COMPLETED", "CANCELLED", "NO SHOW"];
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/reservation/list", {
      "storeCode": this.user.getUserInfo().storeAccess,
      "date": dt
    }).pipe(map((response: any) => {
      if (response.data) {
        let arr: any = [];
        for (let r of response.data.reservations) {
          for (let i of r.list) {
            i.session = r.label;
            i.slot = r.slot;
            i.readOnlyStatus = noStatusChangeFor.indexOf(i.status) >= 0;
            i.bookingMode = i.bookingMode.replace("Reservation", "");
          }
          arr = arr.concat(r.list);
        }
        response.data.reservations = arr;
      }
      return response;
    }));
  }

  fetchStatusList(statusId?: any) {
    let cachedStatusMap = this.getStatusFromCache();
    if (cachedStatusMap && cachedStatusMap[statusId]) {
      return new Observable<any>(observer => {
        observer.next(cachedStatusMap[statusId])
      });
    } else {
      return this.http.get(environment.ORDERPOS_BASEURL + "/rest/reservation/status/" + statusId).pipe(map((response: any) => {
        if (response.messageType == "SUCCESS") {
          cachedStatusMap[statusId] = response;
          localStorage.setItem("_stmd", JSON.stringify(cachedStatusMap));
          return response;
        } else {
          throw new Error("Failed to fetch status list");
        }
      }));
    }

  }

  fetchGuestTitles() {
    if (this.getGuestTitesFromCache().titles) {
      return new Observable<any>(observer => {
        observer.next(this.getGuestTitesFromCache())
      });
    } else {
      return this.http.get(environment.ORDERPOS_BASEURL + "/rest/reservation/namingTitle").pipe(map((response: any) => {
        if (response.messageType == "SUCCESS") {
          localStorage.setItem("_gtd", JSON.stringify(response));
          return response;
        } else {
          throw new Error("Failed to fetch guest titles list");
        }
      }));

    }
  }

  fetchOccasions() {
    if (this.getOccassionsNFromCache().occasions) {
      return new Observable<any>(observer => {
        observer.next(this.getOccassionsNFromCache())
      });
    } else {
      return this.http.get(environment.ORDERPOS_BASEURL + "/rest/reservation/occasion").pipe(map((response: any) => {
        if (response.messageType == "SUCCESS") {
          localStorage.setItem("_ocd.n", JSON.stringify(response));
          return response;
        } else {
          throw new Error("Failed to fetch occasion list");
        }
      }));
    }
  }

  createNewReservation(mode: number, date: string, time: string,
    title: string, name: string, mobile: number, occasion: number,
    adultPax: number, kidPax: number) {
    let payload = {
      "adults": adultPax,
      "branchId": this.user.getUserInfo().branchId,
      "child": kidPax,
      "cityId": this.user.getUserInfo().cityId,
      "companyId": 1,
      "customerName": name,
      "mobileNo": mobile,
      "notes": "",
      "occasionId": occasion,
      "reservationDate": date,
      "reservationTime": time,
      "reservationTypeId": mode,
      "title": title,
      "withoutMobileNo": mobile ? 0 : 1
    }
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/reservation/addReservation", payload);
  }
  rescheduleReservation(bookingId:any, mode: number, date: string, time: string,
    title: string, name: string, mobile: number, occasion: number,
    adultPax: number, kidPax: number){
    let payload = {
      "bookingId" : bookingId,
      "adults": adultPax,
      "branchId": this.user.getUserInfo().branchId,
      "child": kidPax,
      "cityId": this.user.getUserInfo().cityId,
      "companyId": 1,
      "customerName": name,
      "mobileNo": mobile,
      "notes": "",
      "occasionId": occasion,
      "reservationDate": date,
      "reservationTime": time,
      "reservationTypeId": mode,
      "title": title
    }
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/reservation/rescheduleReservation", payload);

  }

  updateReservationStatus(bookingId:string, statusId:number, adult?:number, kid?:number, tableNo?:number, cancelReason?:number, carNo?:string){
    let payload = {
      "adults": adult ? Number(adult) : 0,
      "bookingId": bookingId,
      "cancellationReasonId": Number(cancelReason),
      "carNo": carNo,
      "kids": kid ? Number(kid) : 0,
      "reservationStatus": Number(statusId),
      "tableNo": tableNo ? Number(tableNo) : 0
    }
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/reservation/updateReservation", payload);
  }

  updateCustomerTemperature(bookingId:string, bookingTime:string, bookingType:string, customerName:string,
    mobileNo:string, occasion:string, pax:string, tableNumber:number, date:string, temperatureDetails:any){
    let payload = {
      "customerDetails": {
        "bookingId": bookingId,
        "bookingTime": bookingTime,
        "bookingType": bookingType,
        "customerName": customerName,
        "mobileNo": mobileNo,
        "occasion": occasion,
        "pax": pax,
        "tableNumber": Number(tableNumber),
        "temperatureRecorded": true
      },
      "date": date,
      "paxTemperatureDetails": temperatureDetails,
      "storeCode": this.user.getUserInfo().storeAccess
    }
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/customer/temperature", payload);
  }


  fetchLiveOrdersByTableId(tableId: any) {
    return this.http.get(environment.ORDERPOS_BASEURL + "/rest/order/listByTable/" + this.user.getUserInfo().storeAccess + "/" + tableId + "?live=1")
  }

  fetchBillByTableName(tableName: any) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/order/billPreviewByTableName", {
      "storeCode": this.user.getUserInfo().storeAccess,
      "tableName": tableName
    });
  }

  fetchBillByReceiptNumber(receiptNo: any) {
    return this.http.get(environment.ORDERPOS_BASEURL + "/rest/order/preview/bill/" + receiptNo + "/" +  this.user.getUserInfo().storeAccess);
  }

  settleReceiptNo(receiptNo: string, tenders: any) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/order/bill/settlement", {
      "receiptNo": receiptNo,
      "paymentInfo": tenders,
      "storeCode": this.user.getUserInfo().storeAccess,
    });
  }

  fetchCRSListByStore(dt: string, status?: string) {
    return this.http.get(environment.ORDERPOS_BASEURL + "/rest/order/cutomers/" + this.user.getUserInfo().storeAccess + "/" + dt + "?status=" + status)
  }

  fetchTableList(cached?:boolean) {

    if (this.getTableListFromCache().tables && cached) {
      return new Observable<any>(observer => {
        observer.next(this.getTableListFromCache())
      });
    } else {
      return this.http.get(environment.ORDERPOS_BASEURL + "/rest/table/list/" + this.user.getUserInfo().storeAccess).pipe(map((response: any) => {
        if (response.messageType == "SUCCESS") {
          localStorage.setItem("_tbl", JSON.stringify(response));
          return response;
        } else {
          throw new Error("Failed to fetch table list");
        }
      })) 
    }
    
  }

  fetchLoyaltyListAndBalance(member_id?:number, phone?:string){
    return this.http.post(environment.NODEAPI_BASEURL + "/loyaltytransactions/listBalance", {
      query: {
        "$and" : [
          {
            member_id : member_id
          },
          {
            customer_phone : phone,
          }
        ]
      },
      options : {
        paginate : 1000,
        sort : {createdAt : -1}
      }
    })
  }

  fetchAdvanceListAndBalance(member_id?:number, phone?:string){
    return this.http.post(environment.NODEAPI_BASEURL + "/advanctransaction/listAdvance", {
      query: {
        "$and" : [
          {
            member_id : member_id
          },
          {
            customer_phone : phone,
          }
        ]
      },
      options : {
        paginate : 1000,
        sort : {createdAt : -1}
      }
    })
  }
  

  fetchOccasionList() {
    if (this.getOccassionsFromCache().occasions) {
      return new Observable<any>(observer => {
        observer.next(this.getOccassionsFromCache())
      });
    } else {
      return this.http.get(environment.ORDERPOS_BASEURL + "/rest/occasion/list").pipe(map((response: any) => {
        if (response.messageType == "SUCCESS") {
          localStorage.setItem("_ocd", JSON.stringify(response));
          return response;
        } else {
          throw new Error("Failed to fetch occasion list");
        }
      }));
    }
  }

 /*  fetchBookings(){
    return this.http.get(environment.ORDERPOS_BASEURL + "/bookings.php");
  } */

  fetchBookingsNew($dt?:any){
    return this.http.post(environment.NODEAPI_BASEURL + "/bookings/list",{
      query : {
        bookingDate : {"$eq" : moment($dt || new Date()).format("YYYY-MM-DD")}
      }
    });
  }

  createBooking(custName:string, mobNo: string, pax:number, bookingDate:string, bookingTime:string, numHrs: number, screen: string, tableId:number, status?:string){
    return this.http.post(environment.NODEAPI_BASEURL + "/bookings/create", {
      "customerName": custName,
      "mobileNo": mobNo,
      "pax": pax,
      "bookingDate": bookingDate,
      "bookingTime": bookingTime,
      "numberofHours": Number(numHrs),
      "screen": screen,
      "tableId": tableId,
      "status" : status
    });
  }

  updateBooking(bookingId:number, custName:string, mobNo: string, pax:number, bookingDate:string, bookingTime:string, numHrs: number, screen: string, tableId:number, status?:string){
    return this.http.put(environment.NODEAPI_BASEURL + "/bookings/update/" + bookingId, {
      "customerName": custName,
      "mobileNo": mobNo,
      "pax": pax,
      "bookingDate": bookingDate,
      "bookingTime": bookingTime,
      "numberofHours": Number(numHrs),
      "screen": screen,
      "tableId": Number(tableId),
      "status" : status
    });
  }

  updateBookingStatus(bookingId:number, status:string){
    return this.http.put(environment.NODEAPI_BASEURL + "/bookings/update/" + bookingId, {
      "status": status
    });
  }

/*   saveBookings(data:any){
    return this.http.post(environment.ORDERPOS_BASEURL + "/bookings.php", data);
  } */

  lineVoid(orderDetailId: number) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/order/lineVoid", {
      "orderIds": [orderDetailId]
    });
  }

  tableVoid(receiptNo: number) {
    const headers = new HttpHeaders({'UserId': this.user.getUserInfo().id + ''});
    const payload = {
      receiptNo: receiptNo,
      storeCode: this.user.getUserInfo().storeAccess
    };
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/order/void", payload , {headers:headers});
  }

  tableBillDiscount(orderId:any, discountType:any, discountValue:any, receiptNo:any) {
    const headers = new HttpHeaders({'UserId': this.user.getUserInfo().id + ''});
    const payload = {
      id: orderId,
      discountOn: 'bill',
      discountType: discountType,
      discountValue: discountValue,
      receiptNo: receiptNo,
      storeCode: this.user.getUserInfo().storeAccess
    };
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/order/applyDiscount", payload , {headers:headers});
  }

  transferTable(orderId:any, newId:any){
    let payload = {
      "orderId" : orderId,
      "newTableId": newId
    }
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Bill/transfertable", payload);
  }

  markPunchInPOS(orderId: any, itemsToBeMarked:any) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Bill/pushedtopos/" + orderId,{
      "orderDetailId": itemsToBeMarked
    });
  }
  markBillReady(receiptNo: any, tableName:any) {
    return this.http.post(environment.ORDERPOS_BASEURL + '/rest/order/orderMaster/billReady/' 
    + receiptNo + '/' + this.user.getUserInfo().storeAccess +'?tableName=' + tableName, {});
  }

  fetchBillPreviewByTableName(tableName:any){
    let payload:any = {
      storeCode: this.user.getUserInfo().storeAccess,
      tableName: tableName
    }
    return this.http.post(environment.ORDERPOS_BASEURL + '/rest/order/billPreviewByTableName', payload)
  }

  settleOrder(receiptNo: any, tenderId: any, amount: any) {
    let payload =
      { "receiptNo": receiptNo, "storeCode": this.user.getUserInfo().storeAccess, "paymentInfo": [{ "amount": amount, "remark": "", "tenderId": tenderId }] }
    return this.http.post(environment.ORDERPOS_BASEURL + '/rest/order/bill/settlement', payload)
  }

  fetchTenderList(){
    if (this.getTenderListFromCache().tenderResponse) {
      return new Observable<any>(observer => {
        observer.next(this.getTenderListFromCache())
      });
    } 
    return this.http.get(environment.ORDERPOS_BASEURL + "/rest/tenderMaster/tendersByStoreCode/" + this.user.getUserInfo().storeAccess)
    .pipe(map((response: any) => {
      if (response.messageType == "SUCCESS") {
        localStorage.setItem("_tnd", JSON.stringify(response));
        return response;
      } else {
        throw new Error("Failed to fetch tender list");
      }
    }));
  }

  fetchMenu() {
    if (this.getMenuFromCache().categoryList) {
      return new Observable<any>(observer => {
        observer.next(this.getMenuFromCache())
      });
    } else {
      return this.http.get(environment.ORDERPOS_BASEURL + "/rest/menu/listFoodItem/" + this.user.getUserInfo().storeAccess).pipe(map((response: any) => {
        if (response.messageType == "SUCCESS") {
          sessionStorage.setItem("_mm.o", JSON.stringify(response));
          return response;
        } else {
          throw new Error("Failed to fetch menu");
        }
      }));
    }
  }

  getStatusFromCache() {
    return JSON.parse(localStorage.getItem("_stmd") || "{}");
  }

  getGuestTitesFromCache() {
    return JSON.parse(localStorage.getItem("_gtd") || "{}");
  }

  getOccassionsFromCache() {
    return JSON.parse(localStorage.getItem("_ocd") || "{}");
  }

  getOccassionsNFromCache() {
    return JSON.parse(localStorage.getItem("_ocd.n") || "{}");
  }

  getTenderListFromCache() {
    return JSON.parse(localStorage.getItem("_tnd") || "{}");
  }

  getTableListFromCache() {
    return JSON.parse(localStorage.getItem("_tbl") || "{}");
  }

  getMenuFromCache() {
    return JSON.parse(sessionStorage.getItem("_mm.o") || "{}");
  }

  addOrderItems(items: any, receiptNo: string, tableId: number) {
    let payload = {
      "items": items,
      "receiptNo": receiptNo,
      "storeCode": this.user.getUserInfo().storeAccess,
      "tableId": Number(tableId)
    }
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/order/add", payload);
  }

  requestForBill(tableId: number) {
    return this.http.get(environment.ORDERPOS_BASEURL + "/rest/order/sync/lsRetail/" + tableId + "/"
      + this.user.getUserInfo().storeAccess + "?override=1");
  }

  openTableForGuest(tableId: number, customerName: string, customerPhone: string, pax: number, bookingId: string, occasionId: number) {
    const payload = {
      "adultPax": pax,
      "bookingId": bookingId,
      "captainId": this.user.getUserInfo().id,
      "customerName": customerName,
      "mobileNumber": customerPhone,
      "occasionId": occasionId,
      "storeCode": this.user.getUserInfo().storeAccess,
      "tableId": Number(tableId)
    }
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/order/new", payload);
  }


  fetchReport(dt:any){
    const postData = {
      orderDate: this.datepipe.transform(dt, 'yyyy-MM-dd') || "",
      storeCode: this.user.getUserInfo().storeAccess
    };
    return this.http.post(environment.NODEAPI_BASEURL + "/report/reportDailyOrder", postData);
  }

  fetchMonthlyReport(fromdt:any, todt:any){
    const postData = {
      fromDate: this.datepipe.transform(fromdt, 'yyyy-MM-dd') || "",
      toDate: this.datepipe.transform(todt, 'yyyy-MM-dd') || "",
    };
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/order/monthlyDataByStore", postData);
  }

}
