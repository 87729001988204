import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AppAuthService } from '../_services/auth.service';
import { DataService } from '../_services/data.service';
import { UserSessionService } from '../_services/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private appAuthService: AppAuthService,
    private userSession: UserSessionService,
    private dataService: DataService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.appAuthService.getAuthData()) {
      // logged in so return true
      if (!this.userSession.getCommonDataUserInfo()) {
        const cookieAuthData = JSON.parse(
          atob(this.appAuthService.getAuthData()),
        );
        return this.loginObservable(cookieAuthData)
      } else {
        return true;
      }
    } else {
      this.router.navigate(['auth/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }

  loginObservable(cookieAuthData: any): Observable<boolean> {
    return this.appAuthService
      .login(cookieAuthData.un, cookieAuthData.pw)
      .pipe(
        map(response => {
          return true;
        }), catchError((err) => {
          if(err.statusText == "LOCATION_ERROR"){
            console.info(err);
            this.router.navigateByUrl("/auth/location-failed");
            return of(false);
          }
          return of(false);
        })
      )
  }

}
