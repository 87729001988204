import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HotToastService } from '@ngneat/hot-toast';
import { AppAuthService } from './auth.service';
import { UserSessionService } from './user-session.service';
import { DataService } from './data.service';

@Injectable()
export class HttpCallInterceptor implements HttpInterceptor {

  constructor(private toast:HotToastService, private userSession:UserSessionService, private dataService:DataService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((res:any) => {
        if(res instanceof HttpResponse){
          if(res.body.messageType && res.body.messageType.toLowerCase() == "failed"){
            this.toast.error(res.body.message, {
              theme: 'snackbar',
              duration: 5000,
              dismissible: true,
              position: 'bottom-left',
              icon: '❎'
            });
            throw new Error(res.body.message);
          } 
          if(res.body.messageType && res.body.messageType.toLowerCase() == "location_failed"){
            if(res.body.data){
              this.dataService.setLocationErrorMessage(res.body.message)
              this.userSession.setUserFromAuthResponse(res.body.data);
            }
            this.toast.error(res.body.message, {
              theme: 'snackbar',
              duration: 5000,
              dismissible: true,
              position: 'bottom-left',
              icon: '❎'
            });
            throw new HttpErrorResponse({
                error: res.body.message,
                statusText: 'LOCATION_ERROR',
                url: res.url || ""
              })
          }
        }
        return res;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        throw error;
      })
    )

  }
}
