import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _notifs:any = [];
  private _notifDataChange:Subject<any> = new Subject<any>();

  constructor() { 
    try {
      this._notifs = JSON.parse(sessionStorage.getItem("_n.d") || "[]")
    }catch(e) {
      this._notifs = [];
    }
  }

  onNotificationDataChange(){
    return this._notifDataChange;
  }

  add(msg:string){
    this._notifs.push({
      timestamp : new Date().getTime(),
      read : false,
      value : msg
    });
    sessionStorage.setItem("_n.d", JSON.stringify(this._notifs));
    this._notifDataChange.next({unread: this.getUnreadCount()});
  }

  remove(index:number){
    this._notifs.splice(index , 1);
    sessionStorage.setItem("_n.d", JSON.stringify(this._notifs));
    this._notifDataChange.next({unread: this.getUnreadCount()});
  }

  getNotifications(){
    return this._notifs;
  }

  removeAll(){
    this._notifs = [];
    sessionStorage.setItem("_n.d", JSON.stringify(this._notifs));
    this._notifDataChange.next({unread: this.getUnreadCount()});
  }
  getUnreadCount(){
    let cnt = 0;
    for(let m of this._notifs){
      if(!m.read){
        cnt++;
      }
    }
    return cnt;
  }

  markAllAsRead(){
    for(let m of this._notifs){
      m.read = true;
    }
    sessionStorage.setItem("_n.d", JSON.stringify(this._notifs));
    this._notifDataChange.next({unread: this.getUnreadCount()});
  }
}
