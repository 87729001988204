import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  position = {
    lat : 0,
    lng : 0
  }
  locationError = "";
  constructor() {
  }

  expandDetails(data: string) {
    try {
      return JSON.parse(atob(data)) || {};
    } catch (e) {
      return {};
    }
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {
        //this.setLatLong({ lng: resp.coords.longitude, lat: resp.coords.latitude })
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        //resolve({ lng: 73.1227, lat:19.2300  }); //kalyan
        //resolve({ lng: 72.8875167, lat: 19.0867304  }); //kurla2
      },
        err => {
          console.info(err);
          reject(err);
        });
    });

  }

  setLatLong(data:any){
    this.position = data;
  }


  getLatLong(){
    return this.position
  }

  setLocationErrorMessage(data:string){
    this.locationError = data;
  }


  getLocationErrorMessage(){
    return this.locationError
  }
}
