import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HotToastModule } from '@ngneat/hot-toast';
import { DatePipe } from '@angular/common';
import { HttpCallInterceptor } from './_services/http-call.interceptor';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MODAL_CONFIG_DEFAULT_OVERRIDE } from 'ngx-bootstrap/modal';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCalendarAlt, faChartBar, faClock, faEye, faEyeSlash, faListAlt, faMoneyBill1, faTimesCircle, faTrashAlt, faTrashCan, faUser, faXmarkCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowsRotate, faPaperclip, faRightLeft, faXmark, faIdCardClip, faFileCircleExclamation, faPlusCircle, faTabletAlt, faSyncAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HotToastModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    FontAwesomeModule,
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpCallInterceptor, multi: true },
    { provide: MODAL_CONFIG_DEFAULT_OVERRIDE, useValue: { backdrop: 'static', keyboard: false } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(faLibrary: FaIconLibrary) {
    faLibrary.addIcons(
      faUser,
      faPaperclip, 
      faMoneyBill1, 
      faXmark, 
      faRightLeft, 
      faXmarkCircle, 
      faTrashCan,
      faIdCardClip,
      faFileCircleExclamation,
      faPlusCircle,
      faEye,
      faEyeSlash,
      faTabletAlt,
      faTimesCircle,
      faClock,
      faTrashAlt,
      faCalendarAlt,
      faListAlt,
      faChartBar,
      faArrowsRotate,
      faSyncAlt,
      faCheckCircle);
  }
}
