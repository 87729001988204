import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { UserSessionService } from './user-session.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { EventBusService } from './event-bus.service';
import { DataService } from './data.service';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppAuthService {
  public showLoader = false;
  afAuth: any;
  ngZone: any;

  USER_STORAGE_ID = "_ug.d.2";

  constructor(
    private http: HttpClient,
    public router: Router,
    private userSession: UserSessionService,
    private api:ApiService,
    private eventBus:EventBusService,
    private dataService:DataService
  ) { }

  login(username:string, password:string) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/rest/user/login", {
      "userName": username,
      "password": password,
    }).pipe(map((response: any) => {
      this.validateAuthResponse(response);
      this.showLoader = true;
      return response;
    }));
  }


  saveSetup(typeId:any, storeCode:any, otpHash?:string, otp?:string) {
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Login/signup", {
      "typeId": typeId,
      "userLoginId" : this.userSession.getCommonDataUserInfo().userLoginId,
      "username": this.userSession.getCommonDataUserInfo().username,
      "storeCode": storeCode,
      "commonMasterUserId": this.userSession.getCommonDataUserInfo().userId,
      "latitude": this.dataService.getLatLong().lat,
      "longitude": this.dataService.getLatLong().lng,
      "otpHash" : otpHash,
      "otp" : otp
    })
  }

  resetAccount() {
    return this.http.post(environment.ORDERPOS_BASEURL + "/api/Login/reset", {
      "typeId": this.userSession.getUserInfo().typeId,
      "userLoginId" : this.userSession.getCommonDataUserInfo().userLoginId,
      "username": this.userSession.getCommonDataUserInfo().username,
      "storeCode": this.userSession.getUserInfo().storeAccess,
      "commonMasterUserId": this.userSession.getCommonDataUserInfo().userId
    })
  }


  forgotPasswordGenerate(empCode:string) {
    return this.http.post( "/api/business/UserManagement/generate_otp",{
      "empCode": empCode,
      "otpLength": 4
    })
  }
  submitOTP(userId:any, otp:any) {
    return this.http.post( "/api/business/UserManagement/validate_otp",{
      "otp": Number(otp),
      "userId": Number(userId)
    })
  }
  setNewPassword(userId:any, refNo:any, password:string) {
    return this.http.post( "/api/business/UserManagement/reset_password",{
      "refNo": Number(refNo),
      "userId": Number(userId),
      "newPassword": password
    })
  }
 
  validateAuthResponse(resp:any) {
    if (resp.messageType.toLowerCase() === 'success' && resp.userInfo && (resp.userInfo.type == 'CASHIER' || resp.userInfo.type == 'BM')) {
      this.userSession.setUserFromAuthResponse(resp);
      return resp;
    }
    if(resp.userInfo && (resp.userInfo.type != 'CASHIER' || resp.userInfo.type != 'BM')){
      resp.message = ""
    }
    //INVALID
    if (this.router.routerState.snapshot.url.indexOf('auth/login') < 0) {
      this.logoutUser();
    }
    this.showLoader = false;
    
    throw new Error(resp.message || 'Invalid User Details!');
  }

  setSession(username:string, password:string, email:string, token:string) {
    const userCredentials = { un: username, pw: password, em: email, tk: token };
    const authStr = btoa(JSON.stringify(userCredentials));
    sessionStorage.setItem(this.USER_STORAGE_ID, authStr);
  }

  getAuthData() {
    return sessionStorage.getItem(this.USER_STORAGE_ID) || "";
  }

  logoutUser() {
    this.showLoader = false;
    sessionStorage.removeItem(this.USER_STORAGE_ID)
    this.router.navigate(['/auth/login']);
    this.api.clearCache();
  }
}
