import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Subject } from 'rxjs';
import { NotificationService } from './notification.service';
import { UserSessionService } from './user-session.service';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  private notificationAudio: any;
  public serviceWorker: any;
  topicSubscription: any;
  tableStatusEvent: Subject<any> = new Subject<any>();
  tableSwitchEvent: Subject<any> = new Subject<any>();
  itemsAddEvent: Subject<any> = new Subject<any>();
  itemsStatusChangeEvent: Subject<any> = new Subject<any>();
  itemsCancelledEvent: Subject<any> = new Subject<any>();

  notifConfig:any = {
    enableTableStatusChange : true,
    enableItemStatusChange : true,
    enableItemAdded : false,
    enableItemCancelled : false
  };

  constructor(private userSession: UserSessionService, private notif: NotificationService, private toast: HotToastService) { }

  

  notify(msg: any, err?: any) {
    this.notif.add(msg);
    if(err) {
      this.toast.error(msg)
    } else {
      this.toast.success(msg);
    }
    

    setTimeout(() => {
      this.notificationAudio.play();
    }, 50)

  }

  setNotifConfig(config:any){
    this.notifConfig = config;
  }

  loadAudio() {
    this.notificationAudio = new Audio("../../assets/notification.mp3");
  }

  onTableStatusChange() {
    return this.tableStatusEvent.asObservable();
  }

  onTableSwitchChange() {
    return this.tableSwitchEvent.asObservable();
  }

  onItemsAdded() {
    return this.itemsAddEvent.asObservable();
  }
  onItemsStatusChange() {
    return this.itemsStatusChangeEvent.asObservable();
  }
  onItemsCancelled() {
    return this.itemsCancelledEvent.asObservable();
  }
}
